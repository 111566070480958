<template>
    <div v-if="$shop_categories && $shop_categories.length > 0" class="widget widget_links clearfix">

        <h4>{{ $t("shop.categories.title") }}</h4>
        <ul>
            <li :class="{selected : $shop_isCategorySelected(null)}">
                <a href="javascript:void(0)" @click="onCategoryClick(null)">{{ $t("shop.categories.all") }}</a>
            </li>									
            <li v-for="(category, index) in $shop_categories" :key="'category'+index" :class="{selected : $shop_isCategorySelected(category)}">
                <a href="javascript:void(0)" @click="onCategoryClick(category)">{{category.title}}</a>
            </li>									
        </ul>

    </div>
</template>

<style>

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';

@Component({
    components: {}
})
export default class Categories extends Vue {

    onCategoryClick(category:any) {
        this.$shop.shop.selectCategory(category);
    }

}

</script>