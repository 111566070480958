<template>
  <section class="shop-products content">

    <h1 class="visually-hidden">{{ $t('shop.shop.title', [$shop_shop.name]) }}</h1>

    <div class="content-wrap">

      <div class="container clearfix">

        <div class="row gutter-40 col-mb-80">

          <!-- Sidebar
            ============================================= -->
            <div class="sidebar col-lg-3" v-if="showSideBar">
              <div class="sidebar-widgets-wrap">

                <Categories :productMode="productMode"></Categories>

              </div>
            </div><!-- .sidebar end -->

          <!-- Post Content
            ============================================= -->
            <div :class="(showSideBar ? ' col-lg-9 postcontent' : ' col-lg-12')">

              <div v-if="productsToDisplay && productsToDisplay.length > 0" id="shop" class="shop">

                <ProductList v-for="(product, index) in productsToDisplay" :key="'product'+index+'_'+product._id" :product="product"></ProductList>

              </div>
              <div v-else>

                {{ $t('shop.products.no-product-to-display') }}

              </div> 

            </div><!-- .postcontent end -->
            
        </div>

      </div>

    </div>

    <StickyCart></StickyCart>

  </section><!-- #content end -->

</template>

<style>

  @media (max-width: 991.98px) {
    /* We add border bottom and space to side bar when we wrap */
    .sidebar.col-lg-3 {
      border-bottom: 1px solid #EEE;
      padding-bottom: 20px;
    }
  }

  .widget_links li.selected > a {
    color: var(--primary-color);
  }
</style>


<script lang="ts">
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import { Component, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import ProductList from '../../panels/shop/product/ProductList.vue';
import StickyCart from '../../cms/canvas/shop/StickyCart.vue'
import Categories from '../../panels/shop/sidebar/Categories.vue';

@Component({
  components: {
    ProductList,
    StickyCart,
    Categories
  }
})
export default class Products extends mixins(GenericPage) {

  destroyed() {}

  get showSideBar() {
    return this.$shop_categories && this.$shop_categories.length > 0;
  }

  get productMode() {
    return (this.$route.meta && this.$route.meta.shop) ? this.$route.meta.shop.productMode : undefined;
  }

  get productsToDisplay() {
    let productsToDisplay = this.productMode == 'ACTIVITY' ? this.$shop_activitiesToDisplay : this.$shop_productsToDisplay;
    return productsToDisplay;
  }
  
}
</script>