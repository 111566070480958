<template>
    <div v-if="isProductAvailable" class="add-to-cart" ref="addToCart">
        <Quantity v-bind:value.sync="form.quantity" :minValue="1" :isLoading="isQuantityInCartLoading"></Quantity>
        <button :disabled="form.quantity == 0" :class="buttonCssClass" data-style="zoom-in" type="button" @click="onAddToCartClick">
            <i class="icon-cart"></i>
            {{ $t("shop.add-to-cart") }}
        </button>
        <span :class="inCartCssClass">
            <i class="icon-check"></i>
            {{ $t("shop.product-in-cart") }}
            <button class="button button-dark button-rounded ladda-button product-remove" data-style="zoom-in" @click="onRemoveClick" :title="$t('shop.product.remove-button_title')"><i class="icon-trash2 m-0"></i></button>
        </span>
    </div>
    <div v-else class="add-to-cart">
        <span class="product-out-of-stock">
            {{ $t("shop.product-out-of-stock") }}
        </span>
    </div>
</template>

<style>

</style>

<script lang="ts">
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import Vue from '@fwk-node-modules/vue';
import Quantity from '../../input/Quantity.vue';
import * as Ladda from 'ladda';

@Component({
    components : {
        Quantity
    }
})
export default class AddToCart extends Vue {
    
    @Prop({
        type: Object,
        required: true
    }) readonly product: any

    form:any = {
        quantity: 1
    }

    isQuantityInCartLoading:boolean = false;
    isProductAvailable:boolean = true;

    get quantityInCart() {
        var details = this.$shop_cartProductDetails(this.product);
        if(details) {
            return details.quantity;
        }
        return 0;
    }

    /* Shared with ProductList */
    get buttonCssClass() {
        var cssClass = "button button-3d ladda-button m-0 product-add";
        if(this.quantityInCart > 0) {
            return cssClass + " d-none"
        }
        return cssClass
    }

    /* Shared with ProductList */
    get inCartCssClass() {
        var cssClass = "product-in-cart"
        if(this.quantityInCart > 0) {
            return cssClass
        }
        return cssClass + " d-none"
    }

    laddaSubmit:Ladda.LaddaButton|null = null;
    laddaRemove:Ladda.LaddaButton|null = null;

    created() {
        if(this.quantityInCart > 0) {
            this.form.quantity = this.quantityInCart;
        }
        this.isProductAvailable = this.product.availableStock == undefined || this.product.availableStock > 0;
    }

    mounted() {
        if(this.isProductAvailable) {
            // @ts-ignore   
            var addToCartButton:HTMLButtonElement|null = this.$refs.addToCart.querySelector( 'button.ladda-button' );
            this.laddaSubmit = Ladda.create(addToCartButton!);
            // @ts-ignore
            var removeButton:HTMLButtonElement|null = this.$refs.addToCart.querySelector( '.product-in-cart button.ladda-button' );
            this.laddaRemove = Ladda.create(removeButton!);
        }
    }

    onAddToCartClick(evt:Event) {
        evt.preventDefault();

        this.laddaSubmit!.start();
        this.isQuantityInCartLoading = true;
        this.$shop.cart.addProduct(this.product, this.form.quantity).then((response:any) => {
            if(response.added) {
                
            }
            this.isQuantityInCartLoading = false;
            this.laddaSubmit!.stop();
        });

    }

    onRemoveClick(evt:Event) {
        evt.preventDefault();

        this.laddaRemove!.start();
        this.$shop.cart.removeProduct(this.product).then((response:any) => {
            this.laddaRemove!.stop();
        });
    }

    @Watch('form.quantity')
    onChange(val: any, oldVal: any) {
        if(this.quantityInCart > 0 && val != this.quantityInCart) {
            // We need to update the cart
            this.isQuantityInCartLoading = true;
            this.$shop.cart.updateProduct(this.product, this.form.quantity).then((response:any) => {
                this.isQuantityInCartLoading = false;
            });
        }
    }

    @Watch('quantityInCart')
    onCartUpdated(val: any, oldVal: any) {
        if(val == 0) {
            // The product is not anymore in cart, we update the form with default value
            val = 1;
        }
        this.form.quantity = val;
    }

}
</script>