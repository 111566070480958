<template>
    <div class="product product-list" ref="product">
        <div class="grid-inner g-0">
            <div class="product-image">
                <router-link :to="getViewDetailsPath(product)">
                    <img :src="getThumbnailsURLs(product)[0]" :alt="getProductImageAlt(product)" />
                </router-link>
            </div>
            <div class="product-desc">
                <div class="product-title">
                    <h3>
                        <router-link :to="getViewDetailsPath(product)">
                            {{product.title}} <span v-if="product.year" class="year nocolor">({{product.year}})</span>
                        </router-link>
                    </h3>
                    <h4 v-if="product.subtitle && product.subtitle != ''">{{product.subtitle}}</h4>
                </div>
                
                <div>
                    <div class="product-price" v-if="product.type !== 'ACTIVITY'"><del v-if="product.price && product.price.nonDiscountedAmount != product.price.amount">{{ formatPriceNonDiscountedAmount(product.price) }}</del> <ins>{{ formatPriceAmount(product.price) }}</ins></div>
                    <div class="product-price" v-if="product.type == 'ACTIVITY'"><del v-if="product.pricePerPerson && product.pricePerPerson.nonDiscountedAmount != product.pricePerPerson.amount">{{ formatPriceNonDiscountedAmount(product.pricePerPerson) }}</del> <ins>{{ formatPriceAmount(product.pricePerPerson) }}</ins> {{ $t("shop.activities.price-per-person") }}</div>
                    <div v-if="product.capacity" class="product-capacity"><span>-</span><span>{{product.capacity}}cl</span></div>
                    <div v-if="product.duration" class="product-duration"><span>-</span><span>{{product.duration}}min</span></div>
                </div>

                <div class="flags" v-if="product.isBio">
                    <img src="@public/img/vigneron-online/wine/icon-bio.jpeg" alt="BIO" />
                </div>
                
                <div v-if="product.rating" class="product-rating">
                    <i class="icon-star3"></i>
                    <i class="icon-star3"></i>
                    <i class="icon-star3"></i>
                    <i class="icon-star3"></i>
                    <i class="icon-star-half-full"></i>
                </div>
                
                <LocalizedContent tag="p" v-if="product.description" :localizedContent="product.description"></LocalizedContent>
                <p v-else></p>
                
                <ul v-if="product.options" class="iconlist">
                    <li><i class="icon-caret-right"></i> Dynamic Color Options</li>
                    <li><i class="icon-caret-right"></i> Lots of Size Options</li>
                    <li><i class="icon-caret-right"></i> Delivered in 3-5 Days</li>
                    <li><i class="icon-caret-right"></i> 30-Day Return Policy</li>
                </ul>
                <div class="buttons">
                    <router-link :to="getViewDetailsPath(product)" class="view-details button button-3d">
                        <i class="icon-zoom-in"></i>
                        {{ $t(product.type == 'ACTIVITY' ? "shop.product.view-details-order" : "shop.product.view-details") }}
                    </router-link>
                    <AddToCart v-if="product.type !== 'ACTIVITY'" :product="product" ></AddToCart>
                </div>
            </div>
        </div>
    </div>
</template>

<style>

/* We set the size of the picture */
.product-list .product-image {
    height:250px!important;
    width: 200px;
    padding-top:5px;
    padding-bottom:5px;
    margin-right: 30px;
    flex: 0 0 auto;
}

/* We set the size of the picture */
.product-list .product-image > a {
    height:100%;
}

/* We set the size of the picture */
.product-list .product-image img {
    width: auto;
    height: 100%;
    margin-left:auto;
    margin-right:auto;
}

/* We set shadow and margin for product list item */
.product-list {
    margin: 0 0 40px 0;
    padding: 0;
    width: 100%;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
}

/* We ensure that picture is verticaly aligned with content */
.product-list .grid-inner {
    display:flex;
    align-items: center;
}

/* We ensure that picture and content wrap for mobile */
/* picture takes full width and with small margin bottom */
@media (max-width: 767.98px) {
    .product-list .product-image {
        width: 100%;
        margin-bottom: 20px;
    }
    .product-list .grid-inner {
      flex-wrap: wrap;
    } 
}

/* We display the content next to the picture */
.product-list .product-desc {
    padding-top:10px;
    padding-right:10px;
    padding-bottom: 0px;

    flex: 0 1 auto;
    align-self: stretch;

    display: flex;
    flex-direction: column;
    position:relative;
}

.product-list .product-desc .flags {
    position:absolute;
    right:0px;
    top:0px;
}

.product-list .product-desc .flags img {
    height:80px;
}

@media (max-width: 767.98px) {
    .product-list .product-desc {
      padding-left:10px;
      width:100%;
    } 
}

/* We reduce description margin and ensure it takes possible space */
.product-list .product-desc p {
      flex-grow: 1;
      margin-bottom: 10px;
}

/* We hide the product description on mobile device */
@media (max-width: 767.98px) {
    .product-list .product-desc p {
        display:none;
    }
}

/* We set flex and correct wrapping to full buttons bar and add to cart section */
.product-list .buttons,
.product-list .buttons .add-to-cart {
    display:flex;
    flex-wrap: wrap;
}

/* We set margins for details button and add to cart sectino */
.product-list .buttons .view-details,
.product-list .buttons .add-to-cart {
    margin:0px 30px 10px 0px;
}

</style>

<script lang="ts">
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import AddToCart from './AddToCart.vue';
import Product from '../../../mixins/Product.vue';
import LocalizedContent from '@fwk-client/components/panels/LocalizedContent.vue';

@Component({
    components : {
        AddToCart,
        LocalizedContent
    }
})
export default class ProductList extends mixins(Product) {

    @Prop({
        type: Object,
        required: true
  }) readonly product: any

}
</script>