<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import { languagesTypes } from '@fwk-client/store/types';

@Component({})
export default class Product extends Vue {

  created() {
  }

  mounted() {
  }

  getThumbnailsURLs(product:any) {
    let defaultShape = product.type == 'ACTIVITY' ? require('@public/img/vigneron-online/activity-shape.jpeg') : require('@public/img/vigneron-online/shape.png');
      return this.$shop.product.getThumbnailsURLs(product, defaultShape);
  }

  getPicturesURLs(product:any) {
    let defaultShape = product.type == 'ACTIVITY' ? require('@public/img/vigneron-online/activity-shape.jpeg') : require('@public/img/vigneron-online/shape.png');
      return this.$shop.product.getPicturesURLs(product, defaultShape);
  }

  getViewDetailsPath(product:any) {
        // We display product details page
        var type = product.type;
        var languageCode = this.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
        var code = this.$shop_productCodeFromProduct(product);
        var path = {
            name : type == 'ACTIVITY' ? 'shop-activity' : 'shop-product',
            params : {
                lang : languageCode,
                shopPath : this.$shopContent_path,
                productCode : code
            }
		}
      	return path;
    }

    getProductImageAlt(product:any) {
        return this.$shop.product.getProductTitle(product, this.$shop_shop.name);
    }
  
}
</script>