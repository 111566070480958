<template>
    <div :class="'quantity clearfix' + (isLoading ? ' loading' : '')">
        <input type="button" value="-" class="minus" @click="removeOne()" :disabled="isLoading || input <= minValue">
        <span class="qty">{{input}}</span>
        <input type="button" value="+" class="plus" @click="addOne()" :disabled="isLoading || maxValue && input >= maxValue">
    </div>
</template>

<style>
    
</style>

<script lang="ts">
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import Vue from '@fwk-node-modules/vue';

@Component({})
export default class Quantity extends Vue {
    
    @Prop({
        type: Number,
        required: false,
        default:0,
    }) readonly value!: number;

    @Prop({
        type: Number,
        required: false,
        default:0,
    }) readonly minValue!: number;

    @Prop({
        type: Number,
        required: false,
        default:undefined,
    }) readonly maxValue!: number;

    @Prop({
        type: Boolean,
        required: false,
        default:false,
    }) readonly isLoading!: boolean;
    
    input:any = this.value;

    removeOne() {
        if(this.input > this.minValue) { this.input--; }
    }

    addOne() {
        if(!this.maxValue || this.input < this.maxValue) {
            this.input++;
        }
    }

    @Watch('value')
    onValueChange(val: any, oldVal: any) {
        this.input = val;
    }

    @Watch('input')
    onChange(val: any, oldVal: any) {
        this.$emit('update:value', val);
    }

}
</script>